<template>
<div>

<div class="footer">
  <div class="container">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 footer-center">
            © 2024 情缘. 版权所有.
            &nbsp;&nbsp;|&nbsp;&nbsp;
            邮箱：2993598820@qq.com
            
            <div class="margin-top-10"></div>

        </div>
    </div>
  </div>
</div>

</div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    // handleClick() {
    //   localStorage.removeItem('token');
    //   window.location.replace("/");
    // }
  }
}
</script>
