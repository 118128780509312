<template>
<div>

<div class="menu">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light1">
          <a href="/" class="navbar-brand active">首页</a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <!-- <li class="nav-item">
                <router-link to="/video/list" class="nav-link">来源</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/systemCourses" class="nav-link">体系课</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/courses" class="nav-link">课程</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/examination" class="nav-link">考试</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/questionLibrary" class="nav-link">题库</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/books" class="nav-link">书籍</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/articles" class="nav-link">文章</router-link>
              </li> -->
              <!-- <li class="nav-item">
                <a href="/articles" class="nav-link">文章</a>
              </li> -->
              
              <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                  其他
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="/about">关于</a>
                  <router-link to="/about" class="dropdown-item">关于</router-link>
                  <div class="dropdown-divider"></div>
                  <router-link to="/other/major_developments" class="dropdown-item">大版本更新</router-link>
                  <div class="dropdown-divider"></div>
                  <router-link to="/goals" class="dropdown-item">目标</router-link>
                  <div class="dropdown-divider"></div>
                  <router-link to="/other/update" class="dropdown-item" v-show="!isUpdate">开发更新时间线</router-link>
              
                </div>
              </li> -->

            </ul>
            <!-- <form class="form-inline my-2 my-lg-0">
              <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
              <button class="btn btn-outline-success my-2 my-sm-0" type="submit">搜索</button>
            </form> -->

            <!-- <div v-if="!user">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <router-link to="/register" class="nav-link">注册</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/login" class="nav-link">登录</router-link>
                </li>
              </ul>
            </div> -->

            <div v-if="user">
              <ul class="navbar-nav mr-auto">
                
                <!-- <li class="nav-item">
                  <p href="javascript:void(0)" class="nav-link">
                    你好, {{user.data.username}}
                  </p>
                </li> -->
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                    设置
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <router-link to="/user/personal" class="dropdown-item">个人中心</router-link>
                    <!-- <div class="dropdown-divider"></div> -->
                    <a href="javascript:void(0)" class="dropdown-item"  @click="logoutClick">登出</a>
                  </div>
                </li>
              </ul>
            </div>

          </div>
        </nav>

      </div>
    </div>
  </div>
</div>

<div class="menu-bottom"></div>

</div>
</template>

<script>
export default {
  name: 'Menu',
  props: ['user'],
  data() {
    return {
      isUpdate: true,
      updateItem: '',
    }
  },
  created() {
    if(localStorage.getItem('userId') == 1) {
      this.isUpdate = false;
    }
    
  },
  methods: {
    logoutClick() {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('userDescription');
      // this.$router.push('/');
      window.location.replace("/");
    }
  }
}
</script>
