import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  // 页面
  {
    path: '/', 
    // name: 'Index',
    component: () => import ('@/views/video/list.vue')
  },
  {
    path: '/about',
    component: () => import ('@/views/pages/about.vue')
  },
  {
    path: '/goals',
    component: () => import ('@/views/pages/goals.vue')
  },
  // 视频
  {
    // 视频列表
    path: '/video/list',
    component: () => import ('@/views/video/list.vue')
  },
  {
    // 视频详情
    path: '/video/:id',
    component: () => import ('@/views/video/details.vue')
  },
  // 视频来源
  {
    // 视频来源列表
    path: '/video/source/list',
    component: () => import ('@/views/video/source/list.vue')
  },
  {
    // 视频详情
    path: '/video/source/:mid',
    component: () => import ('@/views/video/source/details.vue')
  },
  // 视频分类
  {
    // 视频分类列表
    path: '/video/category/list',
    component: () => import ('@/views/video/category/list.vue')
  },
  {
    // 视频详情
    path: '/video/category/:id',
    component: () => import ('@/views/video/category/details.vue')
  },
  // 账号
  {
    path: '/login',
    component: () => import ('@/views/login/login.vue')
  },
  {
    path: '/register',
    component: () => import ('@/views/login/register.vue')
  },
  {
    path: '/forget',
    component: () => import ('@/views/login/forget.vue')
  },
  // 课程
  {
    path: '/courses',
    component: () => import ('@/views/course/course.vue')
  },
  {
    path: '/systemCourses',
    component: () => import ('@/views/course/systemCourses.vue')
  },
  {
    path: '/course/:id',
    component: () => import ('@/views/course/details.vue')
  },
  // 考试
  {
    path: '/examination',
    component: () => import ('@/views/examination/index.vue')
  },
  // 题库
  {
    path: '/questionLibrary', 
    component: () => import ('@/views/questionLibrary/index.vue')
  },
  {
    path: '/questions', 
    component: () => import ('@/views/questionLibrary/questions.vue')
  },
  // 书籍
  {
    path: '/books', 
    component: () => import ('@/views/book/index.vue')
  },
  {
    path: '/book', 
    component: () => import ('@/views/book/posts.vue')
  },
  {
    path: '/book/post', 
    component: () => import ('@/views/book/post.vue')
  },
  {
    path: '/book/create', 
    component: () => import ('@/views/book/create.vue')
  },
  // 博客
  {
    path: '/articles', 
    component: () => import ('@/views/article/index.vue')
  },
  {
    path: '/article/category/:id', 
    component: () => import ('@/views/article/index.vue')
  },
  {
    path: '/article/details/:id', 
    component: () => import ('@/views/article/post.vue')
  },
  // {
  //   path: '/book/create', 
  //   component: () => import ('@/views/article/create.vue')
  // },
  // 用户
  {
    path: '/user/personal', 
    component: () => import ('@/views/user/personal.vue')
  },
  {
    path: '/user/resetPassword', 
    component: () => import ('@/views/user/resetPassword.vue')
  },
  // 其他
  {
    path: '/other/major_developments', 
    component: () => import ('@/views/other/major_developments.vue')
  },
  {
    path: '/other/update', 
    component: () => import ('@/views/other/update.vue')
  },
  {
    path: '/test', 
    component: () => import ('@/views/pages/test.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
