<template>
  <div id="app">
    <Menu :user="user" />
    <router-view :user="user" />

    <Footer v-show="!(path ==='/book/create') "></Footer>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Menu from '@/views/layout/menu.vue'
import Footer from '@/views/layout/footer.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    Menu,
    Footer,
  },
  // 判断路由
  mounted() {
    this.path = this.$route.path;
    // console.log(this.$route.path)
  },
  watch:{
      $route(to){
          this.path = to.path
      }
  },
  data() {
    return {
      user: null,
      path:'',
    }
  },
  async created() {
    if(localStorage.getItem('token') != null)
    {
      const response = await axios.post('/api/web/auth/info');
      this.user = response.data;
      // console.log(response.data.data.username)
    }
  }
}
</script>